<template>
  <div v-if="items && items.length" class="margin-bottom-20">
    <span class="keyword keywordreset" V-if="items.length">
      <span @click="reset()" class="keyword-remove"></span>
      <span class="keyword-text">RESET</span>
    </span>

    <span class="keyword" v-for="(item, index) in items" :key="index">
      <span @click="removeFilterItem($event, item)" class="keyword-remove"></span>
      <span class="keyword-text">{{ item.text }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "FilterItems",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    removeFilterItem: function ($event, item) {
      // $event.target.parentElement.classList.toggle("keyword-removed");
      setTimeout(() => {
        this.$emit("removeFilterItem", item);
      }, 100);
    },
    reset: function () {
      this.$emit("reset");
    }
  },
  mounted() {
  }
};
</script>
