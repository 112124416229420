<template>
  <div>
    <div class="listings-container compact-list-layout margin-top-15 margin-bottom-35"
      :class="{ 'compact-list-layout-mobile': isMobile }">
      <div v-if="!jobs.length && isJobsListingsLoaded" class="alert alert-danger" role="alert">No results match your
        search criteria</div>

      <div class="job-listing-container" v-if="!isJobsListingsLoaded">
        <a-skeleton class="job-listing-skeleton" v-for="n in 5" :key="n" active>
        </a-skeleton>
      </div>

      <div class="job-listing-container" v-for="(item, index) in jobs" :key="item.id">
        <h4 v-if="((index) % perPage) == 0 || index == 0" class="page-number">
          <span class="icon-feather-arrow-right"></span>
          Page {{ Math.floor((index + 1) / perPage) + 1 }} of {{ Math.floor(sum / perPage) + 1 }}
        </h4>


        <!-- Revive Adserver Iframe Containers -->
        <div v-if="index % 3 === 0 && index !== 0 && !isMobile" class="banner-container">
          <banner></banner>
          <banner></banner>
        </div>
        <div v-if="index % 3 === 0 && index !== 0 && isMobile" class="banner-container-mobile">
          <banner></banner>
        </div>

        <!-- Job Listing -->
        <result-item :item="item" />
      </div>
    </div>

    <!-- Sentinel element for Intersection Observer -->
    <div class="sentinel" ref="sentinel"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResultItem from "~/components/jobs/ResultItem";
import Banner from '~/components/jobs/Banner';

export default {
  name: "JobsResults",
  components: { ResultItem, Banner },
  data: function () {
    return {
      selectedId: 0,
      lastProcessedIndex: -1,
      reviveId: process.env.REVIVE_BANNER_ID,
      zoneId: process.env.REVIVE_BANNER_ZONE_ID,
      mobileZoneId: process.env.REVIVE_BANNER_MOBILE_ZONE_ID
    };
  },

  computed: {
    ...mapGetters({
      jobs: "jobs/filteredJobsSliced",
      isJobsListingsLoaded: "jobs/isJobsListingsLoaded",
      sum: "jobs/sum",
      perPage: "jobs/perPage",
      isMobile: "isMobile",
      currentPage: "jobs/currentPage"

    }),
    currentPage: {
      get() {
        return this.$store.getters["jobs/currentPage"];
      },
      set(value) {
        this.$store.commit("jobs/setcurrentPage", value);
      },
    },
  },


  mounted() {
    // Intersection Observer
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(this.onIntersection, options);
    this.observer.observe(this.$refs.sentinel);
  },

  methods: {
    onIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.currentPage++;
          //console.log("currentPage:" + this.currentPage);
          // Load more jobs here
          // Example: this.loadMoreJobs();
        }
      });
    },

    bannerIndex(index) {
      return Math.floor((index - 1) / 3) % 6 + 1;
    },
    getAdIframeSrc() {
      const randomNum = Math.floor(Math.random() * 99999999999);
      var zoneId = this.isMobile ? this.mobileZoneId : this.zoneId;
      return `https://servedby.revive-adserver.net/afr.php?zoneid=${zoneId}&cb=${randomNum}`;
    },


    page: function (index) {
      return this.sum % this.perPage;
    },
    viewMore: function (id) {
      this.selectedId = id;
      return false;
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>
<style>
.job-listing-skeleton {
  padding: 30px;
}

.banner-container {
  display: flex;
  justify-content: space-between;
}

.banner-container-mobile {
  display: flex;
  justify-content: center;
}

.banner-ad {
  width: 410px;
  height: 100px;
}

.banner-ad-mobile {
  width: 320px;
  height: 100px;
  /* Additional styling for banners if needed */
}

.sentinel {
  height: 100px;
}
</style>