<template>
  <div>
    <!-- Job Listing -->
    <NuxtLink v-if="false" :to="$route.path + '/item/' + item.id" class="job-listing">
      <!-- <a href="single-job-page.html" class="job-listing"> -->
      <!-- Job Listing Details -->

      <div class="job-listing-details">
        <!-- Logo -->
        <div class="job-listing-company-logo">
          <CompanyLogo :logo="item.logo" :width="50" :anonymous="item.anonymous" />
        </div>

        <!-- Details -->
        <div class="job-listing-description">
          <h3 class="job-listing-title">{{ item.title | truncate(200) }}</h3>

          <!-- Job Listing Footer -->
          <div class="job-listing-footer">
            <ul>

              <li>
                <i class="icon-material-outline-location-on"></i>
                {{ displayLocation(item) }}
              </li>
              <li>
                <i class="icon-material-outline-business-center"></i>
                <span v-if="item.fullTime == 1">Full Time</span>
                <span v-if="item.partTime == 1">Part Time</span>
              </li>
              <li>
                <i class="icon-material-outline-access-time"></i>
                {{ item.date | formatDate }}
              </li>

            </ul>
          </div>
        </div>

        <!-- Bookmark -->
        <Bookmark :itemId="item.id" />
      </div>
    </NuxtLink>

    <a v-else :href="$route.path + '/item/' + item.id" class="job-listing" @click.prevent="openItem(item, $event)">
      <!-- <a href="single-job-page.html" class="job-listing"> -->
      <!-- Job Listing Details -->

      <div class="job-listing-details">
        <!-- Logo -->
        <div class="job-listing-company-logo">
          <CompanyLogo :logo="item.logo" :width="50" :anonymous="item.anonymous" />
          <!-- <img src="images/company-logo-05.png" alt>  -->
        </div>

        <!-- Details -->
        <div class="job-listing-description">
          <h3 class="job-listing-title"> {{ item.title | truncate(200) }}</h3>

          <!-- Job Listing Footer -->
          <div class="job-listing-footer">
            <ul>
              <li>
                <i class="icon-material-outline-location-on"></i>
                {{ displayLocation(item) }}
              </li>
              <li>
                <i class="icon-material-outline-business-center"></i>
                <span v-if="item.fullTime == 1">Full Time</span>
                <span v-if="item.partTime == 1 && item.fullTime == 1">/</span>
                <span v-if="item.partTime == 1">Part Time</span>
              </li>
              <li>
                <i class="icon-material-outline-access-time"></i>
                {{ item.date | formatDate }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Bookmark -->
        <Bookmark :itemId="item.id" />
      </div>
    </a>
    <transition name="fade">
      <single-job :itemResult="item" v-if="showItem" @click="openItem(item)" />
    </transition>
  </div>
</template>
<script>
import Bookmark from "~/components/jobs/Bookmark";
import SingleJob from "~/components/jobs/SingleJob";
import CompanyLogo from '~/components/base/CompanyLogo';
import { mapGetters, mapActions } from "vuex";

export default {


  props: ["item"],
  components: { SingleJob, CompanyLogo, Bookmark },
  data: function () {
    return {
      showItem: false,
      baseUrl: process.env.baseUrl
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
    }),



  },
  methods: {
    // ...mapActions({
    //   updateStatistics: "jobs/updateStatistics"
    // }),
    displayLocation(item) {
      if (item.cityName.toLowerCase() === 'other') {
        if (item.stateName.toLowerCase() === 'other') {
          return 'N/A';
        } else {
          return item.stateName;
        }
      } else {
        return item.cityName;
      }
    },
    openItem: function (item, event) {
      event.preventDefault();
      event.stopPropagation();
      // if (!this.showItem)

      // if (!this.isMobile) {
      // var path = this.$route.path + '/item/' + item.id;
      // this.$router.push(path);

      //  window.location.href = href;
      //this.$router.push(href);
      // return;
      // }

      this.showItem = !this.showItem;
    },

    // async doUpdateStatistics() {
    //   try {
    //     var item = {
    //       itemId: this.item.id,
    //       type: "view"
    //     };
    //     this.updateStatistics({
    //       data: item
    //     })
    //       .then((response) => {

    //       });

    //   } catch (e) {
    //     self.$sentry.captureException(e);
    //   }

    // },
  },
};
</script>

<style>
.job-listing .job-listing-footer {
  background-color: #f9f9f9;
  padding: 20px 8px;
  border-radius: 0 0 4px 4px;
  position: relative;
  width: 100%;
  bottom: 0px;
}
</style>
