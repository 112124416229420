<template>
  <div :class="[isMobile ? 'margin-top-70' : 'margin-top-100', 'container']" ref="topElement">
    <!-- breadcrumbs -->
    <div class="row margin-bottom-25" v-if="!isMobile">
      <div class="col-lg-12">
        <Breadcrumbs :key="key($route)" />
      </div>

    </div>
    <div class="row">


      <!-- filter -->
      <div class="col-xl-3 col-lg-4">
        <jobs-filter v-if="!isMobile" :key="key($route)" @scrollTop="scrollTop" />
      </div>



      <!-- results -->
      <div class="col-xl-9 col-lg-8 content-left-offset">
        <div class="col-lg-12">
          <Breadcrumbs v-if="isMobile" :key="key($route)" />
        </div>

        <button class="enable-filters-button margin-bottom-25" v-if="isMobile" data-toggle="modal"
          data-target="#modalBottom">
          <i class="enable-filters-button-icon"></i>
          <span class="show-text">Show Filters</span>
          <span class="hide-text">Hide Filters</span>
        </button>


        <div class="notify-box">
          <span class="page-title"><span v-if="!isMobile">Search</span> Results ({{ sum }})</span>

          <!-- <div class="switch-container" v-if="!isMobile">
            <label class="switch">
              <input type="checkbox" @change="handleJobAlertSwitch($event)" />
              <span class="switch-button"></span>
              <span class="switch-text">Turn On Job Alerts</span>
            </label>
          </div> -->

          <div class="sort-by">
            <span>Sort by:</span>

            <select class="selectpicker hide-tick" @change="sort($event)">
              <option v-for="item in sortByList" :value="item.id" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- <premium-jobs :jobs="jobs" v-if="!isMobile"/> -->
        <!-- <button
          type="button"
          class="button ripple-effect"
          data-toggle="modal"
          data-target="#modalBottom"
          v-if="isMobile"
        >Refine Results</button> -->


        <!-- results -->
        <jobs-results :key="key($route)" />
      </div>
    </div>


    <div class="modal fade" id="modalBottom" tabindex="-1" role="dialog" aria-labelledby="modalBottomLabel"
      aria-hidden="true" v-if="isMobile">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <!-- <div class="modal-header">
            <h5 class="modal-title" id="modalBottomLabel">Refine Results</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div id="small-dialog" class="zoom-anim-dialog dialog-with-tabs">
            <!--Tabs -->

            <ul class="popup-tabs-nav" style="pointer-events: none;">
              <!-- <li class="active"><a href="#tab">Refine results</a></li> -->

            </ul>

            <div class="popup-tabs-container popup-filter-mobile">
              <!-- <div class="switch-container" v-if="isMobile">
                <label class="switch">
                  <input type="checkbox" @change="handleJobAlertSwitch($event)" />
                  <span class="switch-button"></span>
                  <span class="switch-text">Turn On Job Alerts</span>
                </label>
              </div> -->
              <jobs-filter-mobile v-if="isMobile" :key="key($route)" />
              <button type="button" class="button" id="btnSave" @click="search()">
                Show Results
              </button>
              <button title="Close (Esc)" type="button" @click="search()" class="mfp-close close" data-dismiss="modal"
                aria-label="Search"></button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>


import Breadcrumbs from "./Breadcrumbs.vue";
import JobsResults from "~/components/jobs/JobsResults";
import JobsFilter from "~/components/jobs/JobsFilter";
import JobsFilterMobile from "~/components/jobs/JobsFilterMobile";
import PremiumJobs from "~/components/jobs/PremiumJobs";
import { mapGetters, mapState } from "vuex";

export default {
  name: "JobsList",
  components: {
    JobsFilter,
    JobsFilterMobile,
    JobsResults,
    PremiumJobs,
    Breadcrumbs
  },
  data: function () {
    return {
      title: "Jobs",
      sortByList: [
        { value: "type", direction: "desc", name: "Relevance", id: 1 },
        { value: "date", direction: "desc", name: "Newest", id: 2 },
        { value: "date", direction: "asc", name: "Oldest", id: 3 },
      ],
      initialFilter: {},
      prevMainCategoryId: null,
      prevStateId: null,


      prevFreeText: "" // add this to store the previous mainCategoryId
    };
  },
  computed: {
    ...mapGetters({
      sum: "jobs/sum",
      isMobile: "isMobile",
      // currentPage: "jobs/currentPage",
      metaTags: "jobs/metaTags",
      isJobsListingsLoaded: "jobs/isJobsListingsLoaded"
    }),
  },
  watch: {
    isJobsListingsLoaded(newValue, oldValue) {
      if (newValue === true) {
        // Perform the action after data is fetched
        //console.log("isjoblistingsloaded");
        this.onDataFetched();
      }
    }
  },
  methods: {
    scrollTop() {
      this.$refs.topElement.scrollIntoView({ behavior: 'smooth' });
    },

    key(route) {
      return route.fullPath;
    },
    sort: function ($event) {
      var id = $event.target.value;
      var sortBy = this.sortByList.find((x) => x.id == id);
      this.$store.commit("jobs/setFilter", { sortBy });
    },

    isServerSearchNeeded(currentMainCategoryId, currentFreeText, currrentStateId) {
      return this.prevFreeText != currentFreeText
        || this.prevMainCategoryId != currentMainCategoryId
        || this.prevStateId != currrentStateId;
    },
    handleJobAlertSwitch(event) {
      if (event.target.checked) {
        this.$confirm({
          title: 'Create Job Alert',
          content: 'Do you want to save this search as a job alert?',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            this.saveSearchAsAlert();
          },
          onCancel: () => {
            event.target.checked = false; // Revert the switch
          },
        });
      }
    },
    saveSearchAsAlert() {
      const filter = this.$store.state.jobs.filter;
      const queryParams = {
        mainCategoryId: filter.mainCategoryId ?? '',
        subCategoryId: filter.subCategoryId ?? '',
        stateId: filter.stateId === 0 ? '' : filter.stateId ?? '',
        cityId: filter.cityId === 0 ? '' : filter.cityId ?? '',
        emailFrequency: 'daily', // Default value
        freeText: filter.freeText ?? '',
        notificationMethod: 'email', // Default notification method

        // Individual boolean job type fields
        freelance: !!filter.freelance,
        partTime: !!filter.partTime,
        fullTime: !!filter.fullTime,
        hybrid: !!filter.hybrid,
        internship: !!filter.internship,
        temporary: !!filter.temporary,
        remote: !!filter.fromHome, // Previously "fromHome"
      };
      // Ensure all query params are properly included, even if empty
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      $('.mfp-close').click();
      // Redirect to job alerts page with query parameters
      this.$router.push(`/dashboard/alerts?${queryString}`);
    },

    search: function () {
      //close the dialog window
      $('.mfp-close').click();

      const currentMainCategoryId = this.$store.state.jobs.filter.mainCategoryId;
      const currrentStateId = this.$store.state.jobs.filter.stateId;
      const currentFreeText = this.$store.state.jobs.filter.freeText;
      if (!this.isServerSearchNeeded(currentMainCategoryId, currentFreeText, currrentStateId))
        return true;

      this.prevMainCategoryId = currentMainCategoryId; // Update the previous ID
      this.prevStateId = currrentStateId;
      this.prevFreeText = currentFreeText;

      $nuxt.$root.$loading.start();
      this.$store.dispatch("jobs/getJobs").then(() => {
        $nuxt.$root.$loading.finish();
      });
    },

    onDataFetched() {
      this.prevMainCategoryId = this.$store.state.jobs.filter.mainCategoryId;
      this.prevStateId = this.$store.state.jobs.filter.stateId;
      this.prevFreeText = this.$store.state.jobs.filter.freeText;
    },
    errorCaptured: function (error) {
      console.log(error);
      this.$sentry.captureException(error);
    },
  },

  mounted() {

  },
};
</script>
<style scoped>
.modal-header .close {
  margin-top: 16px !important;
}

.popup-filter-mobile {
  padding: 20px;
}

.sidebar-container {
  margin-top: 0;
}
</style>
