<template>
  <div class="sidebar-container">
    <!-- filter items -->
    <div class="sidebar-widget">
      <filter-items :items="filterItems" @reset="reset" @removeFilterItem="removeFilterItem" />
      <div class="clearfix"></div>
    </div>

    <!-- Location -->
    <div class="sidebar-widget">
      <h3>Location</h3>
      <select class="selectpicker" data-selected-text-format="count" data-size="7" title="All States" id="stateId"
        v-model="stateId" @change="setFilter($event)">
        <option v-for="item in stateIdData" :value="item.id" :key="item.id">
          {{ item.name }} ({{ item.sum }})
          <NuxtLink class="selectlink" :to="'?location=' + item.qstring">{{ item.name }} ({{ item.sum }})</NuxtLink>
        </option>
      </select>
      <!-- Sub Categories -->
      <select :disabled="!stateId" class="selectpicker margin-top-20" data-selected-text-format="count" data-size="7"
        title="All Cities" multiple id="cityId" v-model="cityId" @change="setFilter($event)">
        <option v-for="item in cityIdData" :value="item.id" :key="item.id">
          {{ item.name }} ({{ item.sum }})
          <NuxtLink class="selectlink" :to="'?location=' + item.qstring">{{ item.name
            }}
            ({{
        item.sum }})</NuxtLink>
        </option>
      </select>
    </div>
    <!-- Keywords -->
    <div class="sidebar-widget">
      <h3>Keywords</h3>
      <div class="keywords-container">
        <div class="keyword-input-container">
          <input type="text" class="keyword-input" v-model="freeText" placeholder="e.g. job title" />
          <button class="keyword-input-button ripple-effect" @click="setFilter($event, 'freeText')">
            <i class="icon-material-outline-add" id="freeText"></i>
          </button>
        </div>
        <div class="keywords-list" style="height: auto">
          <!-- keywords go here -->
        </div>
        <div class="clearfix"></div>
      </div>
    </div>

    <!-- Category -->
    <div class="sidebar-widget">
      <h3>Category</h3>
      <select class="selectpicker" data-selected-text-format="count" data-size="7" title="All Categories"
        id="mainCategoryId" v-model="mainCategoryId" @change="setFilter($event)">
        <option v-for="item in mainCategoryIdData" :value="item.id" :key="item.id">
          {{ item.name }} ({{ item.sum }})
          <NuxtLink class="selectlink" :to="'/' + item.qstring">{{ item.name }} ({{ item.sum }})</NuxtLink>
        </option>
      </select>
      <!-- Sub Categories -->
      <select :disabled="!mainCategoryId" class="selectpicker margin-top-20" data-selected-text-format="count"
        data-size="7" title="All Sub Categories" multiple id="categoryId" v-model="categoryId"
        @change="setFilter($event)">
        <option v-for="item in categoryIdData" :value="item.id" :key="item.id">
          {{ item.name }} ({{ item.sum }})
          <NuxtLink class="selectlink" :to="'/' + $route.params.mainCategory + '/' + item.qstring">{{ item.name
            }}
            ({{
        item.sum }})</NuxtLink>
        </option>
      </select>
    </div>

    <!-- Job Types -->
    <div class="sidebar-widget">
      <h3>Job Type</h3>

      <div class="switches-list">
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="fromHome" v-model="fromHome" @change="setFilter($event)" />
            <span class="switch-button"></span> Remote
          </label>
        </div>
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="hybrid" v-model="hybrid" @change="setFilter($event)" />
            <span class="switch-button"></span>Hybrid

          </label>
        </div>
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="fullTime" v-model="fullTime" @change="setFilter($event)" />
            <span class="switch-button"></span> Full Time
          </label>
        </div>
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="partTime" v-model="partTime" @change="setFilter($event)" />
            <span class="switch-button"></span> Part Time
          </label>
        </div>

        <!-- <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="internship" v-model="internship" @change="setFilter($event)" />
            <span class="switch-button"></span> Internship
          </label>
        </div> -->
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="temporary" v-model="temporary" @change="setFilter($event)" />
            <span class="switch-button"></span> Temporary
          </label>
        </div>
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="freelance" v-model="freelance" @change="setFilter($event)" />
            <span class="switch-button"></span> Freelance
          </label>
        </div>

      </div>
    </div>

    <!-- Top Locations -->
    <!-- <div class="sidebar-widget">
      <h3>Top Locations</h3>
      <div>
        <ul class="topLocations">
          <li v-for="item in topLocationsData" :value="item.id" :key="item.id">
            <a class="selectlink" @click.prevent="onTopLocationSelected(item.longName)" :href="$route.params.mainCategory +
        '?location=' +
        item.longName
        ">
              {{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import FilterItems from "~/components/jobs/FilterItems";
import axios from "axios";
import { createNamespacedHelpers } from "vuex";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "getFilter",
  mutationType: "updateFilter",
});

export default {
  name: "JobsFilterMobile",

  data: function () {
    return {
      title: "Jobs",
      filterItems: [],
      baseUrl: process.env.baseUrl,
      selectedPlace: null, // To store the fetched place details

    };
  },
  computed: {
    ...mapState("jobs", {
      mainCategoryIdData: (state) => state.mainCategoryIdData,
      categoryIdData: (state) => state.categoryIdData,
      freelanceData: (state) => state.freelanceData,
      fromHomeData: (state) => state.fromHomeData,
      partTimeData: (state) => state.partTimeData,
      fullTimeData: (state) => state.fullTimeData,
      hybridData: (state) => state.hybridData,
      // internshipData: (state) => state.internshipData,
      temporaryData: (state) => state.temporaryData,
      topLocationsData: (state) => state.topLocationsData,
      locationData: (state) => state.locationData,
      filterDefinition: (state) => state.filterDefinition,
      stateIdData: (state) => state.stateIdData,
      cityIdData: (state) => state.cityIdData,
    }),
    ...mapFields("jobs", [
      "mainCategoryId",
      "categoryId",
      "stateId",
      "cityId",
      "freelance",
      "partTime",
      "fullTime",
      "hybrid",
      "temporary",
      "fromHome",
      // "internship",
      "freeText",
      "radius",
      "location",
    ]),
    ...mapGetters({
      isJobsListingsLoaded: "jobs/isJobsListingsLoaded"
    }),
  },

  components: {
    FilterItems,
  },


  watch: {
    isJobsListingsLoaded(newValue) {
      if (newValue === true) {
        // Perform the action after data is fetched
        //client side
        this.onDataFetched();
      }
    }
  },

  methods: {
    async onDataFetched() {
      if (this.mainCategoryId > 0) {
        $("#mainCategoryId").val(this.mainCategoryId);
        this.addSingleFilterItem(this.mainCategoryId, "mainCategoryId");
      }

      if (this.categoryId > 0) {
        $("#categoryId").val(this.categoryId);
        this.addSingleFilterItem(this.categoryId, "categoryId");
      }
      if (this.stateId > 0) {
        $("#stateId").val(this.stateId);
        this.addSingleFilterItem(this.stateId, "stateId");
      }

      if (this.cityId > 0) {
        $("#cityId").val(this.cityId);
        this.addSingleFilterItem(this.cityId, "cityId");
      }

      if (this.freeText) {
        this.addSingleFilterItem(this.freeText, "freeText");
      }

      if (this.fromHome) {
        this.addSingleFilterItem(this.fromHome, "fromHome");
      }


      // await this.$initAutocomplete('autocomplete-input', {
      //   types: ["geocode"],
      //   language: "en",
      //   componentRestrictions: { country: ["us", "il"] }
      // }).then(autocomplete => {
      //   if (autocomplete) {
      //     this.autocomplete = autocomplete;
      //     this.autocomplete.setFields([
      //       "geometry", "formatted_address", "address_components"
      //     ]);
      //     this.autocomplete.addListener('place_changed', this.onPlaceSelectedFromAutocomplete);
      //     setTimeout(function () {
      //       $(".pac-container").prependTo("#autocomplete-container");
      //     }, 300);
      //   }
      // });
      // if (
      //   this.location &&
      //   this.location.length > 0 &&
      //   this.locationData.length > 0
      // ) {
      //   var id = this.locationData.length > 0 ? this.locationData[0].id : 0;
      //   var input = document.getElementById("autocomplete-input");
      //   input.value = this.locationData[0].name;

      //   this.addSingleFilterItem(id, "location");
      //   // Fetch place details and then manually call locationChanged
      //   this.fetchPlaceDetails(this.locationData[0].name, false)
      // }

    },

    onTopLocationSelected(location) {

      this.fetchPlaceDetails(location);
    },

    removeFilterItem: function (item) {
      var arr = this.filterItems;
      var name = item.name;
      //remove filter item
      const index = arr.findIndex(
        (x) => x.id == item.id && x.name == item.name
      );
      if (index > -1) arr.splice(index, 1);
      if (item.name == "location") this.removeLocationItem();

      //remove filter id
      var filterItem = this[item.name];
      if (filterItem.constructor == Array) {
        {
          const i = filterItem.findIndex((x) => x == item.id);
          if (i > -1) {
            var newFilterItem = filterItem.slice();
            newFilterItem.splice(i, 1);
            this.updateFilter({ [name]: newFilterItem });
          }
        }
      } else if (filterItem.constructor == Number) {
        {
          this.updateFilter({ [name]: 0 });
        }
      } else {
        {
          this.updateFilter({ [name]: "" });
        }
      }

      //search again based on new filter
      this.setFilter(undefined, item.name);
    },
    removeLocationItem() {
      var input = document.getElementById("autocomplete-input");
      input.placeholder = "City, State or ZIP";
      input.value = "";
      this.location = 0;
    },

    addSingleFilterItem: function (id, name) {
      //don't add item when it's undefined
      if (!id) return;

      var arr = this[name + "Data"];

      var selected = arr ? arr.filter((x) => x.id == id)[0] : undefined;
      var text = selected ? selected.name : "";

      if (name == "freeText") text = id;
      if (!text) return;

      var item = {
        id: id,
        text: text,
        name: name,
      };
      this.filterItems.push(item);
    },

    reset: function () {
      this.filterItems = [];
      this.resetFilter();
      //this.removeLocationItem();
    },

    qstringPrefix() {
      if (this.$route.query) return "&";
      else return "?";
    },

    addFilterItem: function (filterItemDef) {
      if (!filterItemDef.multiple) {
        var id = this[filterItemDef.name];
        var arr = this.filterItems;
        //remove existing filter item
        this.filterItems = arr.filter((x) => x.name !== filterItemDef.name);
        if (filterItemDef.name == "location" && id) id = 1;

        this.addSingleFilterItem(id, filterItemDef.name);
      } else {
        var ids = this[filterItemDef.name];
        var arr = this.filterItems.filter((x) => x.name == filterItemDef.name);

        ids.forEach((id) => {
          var index = arr.findIndex((x) => x.id == id);

          //add filter item that is not existed yet on filter items
          if (index == -1) this.addSingleFilterItem(id, filterItemDef.name);
        });

        //remove filter item when it's not exist on filter ids (when unselect)
        arr.forEach((x) => {
          var index = ids.findIndex((id) => id == x.id);
          if (index == -1) this.removeFilterItem(x);
        });
      }
    },
    setFilter: function ($event, name) {
      var self = this;
      var name = $event && $event.target.id ? $event.target.id : name;
      var filterItemDef = this.filterDefinition.find((x) => x.name == name);

      //reset sub category id and city id when main category or state is selected
      if (filterItemDef.resetSubCategory) {
        this[filterItemDef.subCategory] = [];
        var item = this.filterDefinition.find(
          (x) => x.name == filterItemDef.subCategory
        );
        this.addFilterItem(item);
        this["set" + filterItemDef.subCategory + "Data"]();
      }

      this.addFilterItem(filterItemDef);
    },

    setcategoryIdData: function () {
      var self = this;
      return this.getSubCategories({
        mainCategoryId: this.mainCategoryId,
      }).catch((ex) => {
        self.$handleExceptionWithSentry(ex);
      });
    },
    setcityIdData: function () {
      var self = this;
      return this.getCities({
        stateId: this.stateId,
      }).catch((ex) => {
        self.$handleExceptionWithSentry(ex);
      });
    },
    fetchPlaceDetails(predefinedLocationName, searchAgain = true) {
      var self = this;

      var request = {
        query: predefinedLocationName,
        fields: ["geometry", "formatted_address"]
      };

      var service = new google.maps.places.PlacesService(document.createElement('div'));
      service.findPlaceFromQuery(request, function (results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          self.selectedPlace = results[0];
          if (searchAgain) {
            self.locationChanged();
            $('.mfp-close').click();
          }
        } else {
          console.error('Error fetching place details:', status);
          callback(null); // Handle the error appropriately in your application
        }
      });
    },


    locationChanged() {
      this.setLocation();
    },

    onPlaceSelectedFromAutocomplete() {
      this.selectedPlace = this.autocomplete.getPlace();
      this.locationChanged();
    },
    getCityFromFormattedAddress(formattedAddress) {
      var parts = formattedAddress.split(',');

      if (parts.length >= 2) {
        return parts[0].trim();
      } else {
        return null;
      }
    },
    async setLocation() {
      const place = this.selectedPlace;
      if (!place)
        return;
      $nuxt.$root.$loading.start();
      var input = document.getElementById("autocomplete-input");
      if (place.address_components)
        var city =
          (place.address_components[0] &&
            place.address_components[0].long_name) ||
          "";
      else
        var city = this.getCityFromFormattedAddress(place.formatted_address);

      input.blur();
      setTimeout(function () {
        input.value = city;
      }, 500);

      var locationData = [{ name: city, id: 1 }];

      this.$store.commit("jobs/setLocationData", locationData);

      var address = place.formatted_address;

      this.location = await this.$global.getLocationPolygon(
        address,
        this.radius
      );

      this.setFilter(undefined, "location");
      $nuxt.$root.$loading.finish();
    },

    ...mapActions("jobs", {
      resetFilter: "resetFilter",
      getSubCategories: "getSubCategories",
      getCities: "getCities"
    }),
    ...mapMutations("jobs", { updateFilter: "setFilter" }),
  },

  updated: function () {
    this.$nextTick(function () {
      $(".selectpicker").selectpicker("refresh");
    });
  },
  head() {
    return {
      script: [
        {
          //src: "https://apis.google.com/js/platform.js"
          // src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBrTrorHTE-6cPNubZrc0OWVfmf9-4osaw&libraries=places&language=en",
        },
      ],
    };
  },
  mounted() {
    //server side
    if (this.isJobsListingsLoaded)
      this.onDataFetched();

    if (process.browser) {
      require("bootstrap-select");
      $(".selectpicker").selectpicker("refresh");
    }


  },
};
</script>
<style>
body .pac-container {
  left: 0 !important;
  top: 100% !important;
}
</style>
